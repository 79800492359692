import React from 'react';
import { Link } from 'react-router-dom';

function TermsofService() {

  return (
    <div className="bg-gray-50 flex flex-col min-h-screen">
        <div className="flex min-h-screen items-center justify-center">
            <div>
                <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                    <div className="relative flex w-full max-w-[48rem] flex-row rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                        <div className="p-6">
                            <h4 className="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                               Terms of Service
                            </h4>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Effective Date: 11/20/2024
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                These Terms and Conditions ("Terms", "Terms of Service") govern your use of the website pairedplate.com ("the Site"), operated by Kowaaluh LLC ("we", "our", "us").
                                By accessing or using the Site, you agree to comply with and be bound by these Terms.
                                If you do not agree with these Terms, please do not use the Site.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                1. Acceptance of Terms
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                By accessing or using the Site, you agree to comply with these Terms and all applicable laws and regulations. If you do not agree to these Terms, you must not use the Site.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                2. Changes to Terms
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We reserve the right to update, amend, or change these Terms at any time. When changes are made, the updated version will be posted on this page with the revised "Effective Date." You are encouraged to review these Terms periodically for updates.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                3. Verification of Vegan Status
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                While we strive to provide accurate and reliable information about the food items and restaurants listed on our Site, we cannot guarantee that all items and restaurants listed on this site are vegan.
                                It is your responsibility to verify and confirm that the food and dining options are suitable for your dietary needs, including being vegan.
                                By continuing on this site, you agree that Kowaaluh LLC is not responsible for any issues or health concerns that may arise due to your dietary choices.
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Before patronizing any restaurant or consuming any food recommended or listed on our Site:
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Verify: Always confirm with the restaurant or food provider that the food is vegan and suitable for your specific dietary requirements.
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Consult with Staff: If in doubt, consult with the restaurant's staff to ensure that the food does not contain animal products or ingredients that may not meet your dietary preferences or restrictions.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                4. User Responsibilities
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We are committed to maintaining a positive and respectful environment on the Site. You agree to:
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Respect Others: Treat all users with respect and dignity. Harassment, offensive language, or discriminatory behavior will not be tolerated.
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                No Harassment: You shall not engage in any behavior that could be considered harassment or abuse of other users, including but not limited to verbal abuse, offensive remarks, or threatening behavior.
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Compliance: You agree to comply with all applicable laws, regulations, and guidelines when using the Site. This includes respecting the rights of others, including their right to privacy.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                5. Prohibited Uses
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                You may not use the Site for any unlawful or prohibited purpose. Without limiting the generality of this restriction, you agree not to:
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Post or share content that is illegal, offensive, or violates the rights of others.
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Engage in any behavior that disrupts, interferes with, or limits the functionality of the Site.
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Use the Site to transmit malware, spam, or other harmful content.
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Attempt to access any areas of the Site that are restricted or not intended for your use.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                6. Account Suspension and Termination
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                 We reserve the right to suspend or terminate your access to the Site if you violate these Terms, engage in harmful activities, or for any other reason, at our discretion. Upon termination, your right to use the Site will immediately cease.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                7. Intellectual Property
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                All content on the Site, including text, images, logos, trademarks, and other materials, are the property of Kowaaluh LLC or third parties and are protected by intellectual property laws. You may not use any content from the Site without prior written consent, except for personal, non-commercial use.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                8. Limitation of Liability
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                To the fullest extent permitted by law, Kowaaluh LLC will not be liable for any damages or losses arising from the use of the Site, including but not limited to direct, indirect, incidental, or consequential damages.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                9. Privacy and Data Collection
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Your use of the Site is governed by our Privacy Policy, which explains how we collect, use, and protect your information.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                10. Links to Third-Party Sites
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                The Site may contain links to third-party websites or services that are not operated or controlled by us. We are not responsible for the content, privacy policies, or practices of these third-party sites.
                                We recommend reviewing the terms and privacy policies of any third-party sites you visit.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                11. Dispute Resolution
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Any disputes arising from or related to these Terms will be resolved through binding arbitration, rather than through court trials.
                           </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                12. Governing Law
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                These Terms are governed by and construed in accordance with the laws of the United States, without regard to its conflict of law principles.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                13. Contact Us
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                If you have any questions or concerns about these Terms and Conditions,
                                 please
                               <Link to="/contactus" className="font-semibold mx-1">
                                  contact us.
                               </Link>
                            </p>
                            <div className="flex justify-end ">
                                <Link to="/">
                                    <button
                                        type="button" className="rounded bg-green-950 px-3 py-1.5 font-bold text-white sm:rounded-l-md sm:rounded-r-md">
                                        Back to site
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
}

export default TermsofService;