/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-1",
    "aws_appsync_graphqlEndpoint": "https://6g3tcoukvvbmtidjg3nhobtclu.appsync-api.us-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6gdhcsxbk5ed3bi3dpd6izl2cy",
    "aws_cognito_identity_pool_id": "us-west-1:511c58de-b614-4ee0-843c-159ceced208d",
    "aws_cognito_region": "us-west-1",
    "aws_user_pools_id": "us-west-1_4V4GlHHTt",
    "aws_user_pools_web_client_id": "eg1m4ec09oej3l2veovcputdc",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "pairedplatestorageb8a2e-dev",
    "aws_user_files_s3_bucket_region": "us-west-1"
};


export default awsmobile;
