import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {

  return (
    <div className="bg-gray-50 flex flex-col min-h-screen">
        <div className="flex min-h-screen items-center justify-center">
            <div>
                <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                    <div className="relative flex w-full max-w-[48rem] flex-row rounded-xl bg-white bg-clip-border text-gray-700 shadow-md">
                        <div className="p-6">
                            <h4 className="block mb-2 font-sans text-2xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
                            Privacy Policy
                            </h4>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Effective Date: 11/20/2024
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                Kowaaluh LLC ("we", "our", "us") respects your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard the email address that you provide to us when using our website pairedplate.com (the "Site"). Please read this policy carefully. By using our Site, you consent to the practices described in this policy.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                1. Information We Collect
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We collect personal identification information when you voluntarily provide it to us. Specifically, we collect:
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                2. How We Use Your Information
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We use your email address for the following purposes:
                            </p>
                            <p className="block font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                To communicate with you, respond to inquiries, or provide customer support.
                            </p>
                            <p className="block font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                To send updates or other communications that you have requested.
                            </p>
                            <p className="block font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                To improve and personalize your experience on the Site.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                3. How We Protect Your Information
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We take the security of your email address seriously and implement reasonable security measures to protect it from unauthorized access, disclosure, or alteration. This includes using secure servers and encryption where appropriate.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                4. Sharing Your Information
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following cases:
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                With Service Providers: We may share your email address with third-party service providers who assist us in running our Site or providing services to you (e.g., email delivery services).
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Legal Requirements: We may disclose your information if required by law, or if we believe it is necessary to protect our rights or comply with legal processes.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                5. Third-Party Links
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Our Site may contain links to third-party websites that are not operated or controlled by us. Please be aware that we are not responsible for the privacy practices of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit through links on our Site.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                6. Your Data Protection Rights
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Depending on your jurisdiction, you may have the following rights regarding your personal data:
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                The right to access the personal information we hold about you.
                            </p>
                            <p className="font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                The right to correct or update your email address if it is inaccurate or incomplete.
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                The right to request that we delete your email address, subject to any legal obligations.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                7. Children’s Privacy
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                Our services are not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children. If we discover that we have collected personal data from a child, we will take steps to delete that information as soon as possible.
                            </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                8. Changes to This Privacy Policy
                            </p>
                             <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                 We may update this privacy policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When we make updates, we will revise the "Effective Date" at the top of this page. We encourage you to review this policy periodically to stay informed about how we are protecting your personal information.
                             </p>
                            <p className="block mb-2 font-sans text-base antialiased font-normal leading-relaxed text-gray-700">
                                9. Contact Us
                            </p>
                            <p className="font-sans mb-2 text-base antialiased font-normal leading-relaxed text-gray-700">
                                If you have any questions or concerns about this privacy policy or how we handle your personal information, please
                                <Link to="/contactus" className="font-semibold mx-1">
                                  contact us.
                                </Link>
                            </p>
                            <div className="flex justify-end ">
                                <Link to="/">
                                    <button
                                        type="button" className="rounded bg-green-950 px-3 py-1.5 font-bold text-white sm:rounded-l-md sm:rounded-r-md">
                                        Back to site
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  );
}

export default PrivacyPolicy;